@import '@/styles/variables.scss';

.loginLayout {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  .loginContainer {
    display: flex;
    width: 440px;
    padding: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;

    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.06);
    background: $white;
  }
}

.form {
  display: grid;
  width: 100%;
  gap: 24px;
  .input-container {
    display: grid;
    gap: 16px;
    .input {
      margin: 0;
    }
  }
  .button-container {
    display: grid;
    gap: 12px;
    .button {
      margin: 0;
    }
  }
  .password-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    .info-text {
      display: block;
      text-align: center;
      color: rgba(0, 0, 0, 0.45);
    }
  }
}
